.skillBox {
    padding: 10px;
    width: 25%;
}

.skillImage {
    width: 100px;
    height: 100px;
    margin:  0 auto;
    display: block;
}

.skillName {
    margin-top: 5px;
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
}

.skillTime {
    font-size: 1.1em;
    text-align: center;
}

.skillProf {
    margin-top: 3px;
    font-size: 1.1em;
    text-align: center;
}

@media all and (max-width: 580px) {
    .skillImage {
        width: 50px;
        height: 50px;
    }

    .skillName {
        font-size: 1.3em;
    }

    .skillTime {
        font-size: 1.0em;
    }

    .skillProf {
        font-size: 1.0em;
    }
}

@media all and (max-width: 480px) {
    .skillBox {
        width: 48%;
        padding: 0px;
        margin-bottom: 20px;
    }
}